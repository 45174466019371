import styles from '../css/app.scss';

import { Application } from 'stimulus'
import ButtonsContorller from './controllers/buttons'

const Turbolinks = require('turbolinks')
const lazysizes = require('lazysizes')


const main = async () => {

    Turbolinks.start()
    const application = Application.start()
    application.register('buttons', ButtonsContorller)

    document.addEventListener('turbolinks:load', function () {
    })
}

// Execute async function
main().then((value) => {
})
