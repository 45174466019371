import { Controller } from "stimulus"

export default class extends Controller {
    connect() {
      }
    
    onClick() {
        const elements = document.querySelectorAll('.' + this.element.getAttribute('data-name'))
        elements.forEach(el => {
            el.classList.toggle('button-selected')
        })
    }  
      
}